













































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniToggleSwitch from "@/components/inputs/SygniToggleSwitch.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import SygniRadio from "@/components/inputs/SygniRadio.vue";
import SygniTextArea from "@/components/inputs/SygniTextArea.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniInput from "@/components/inputs/SygniInput.vue";
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import SygniLoader from '@/components/layout/SygniLoader.vue';
import {email, required} from "vuelidate/lib/validators";
import SygniInputError from "@/components/inputs/SygniInputError.vue";
import {mapState} from "vuex";
import { Watch } from 'vue-property-decorator';
import GenproxButton from '@/components/buttons/GenproxButton.vue';
import ChevronLeftIcon from '@/assets/icons/heroicons/ChevronLeftIcon.vue';

Component.registerHooks(['validations'])
@Component({
  components: {
    SygniInputError,
    SygniToggleSwitch,
    SygniRadio,
    ExpandCollapse,
    SygniRoundedButton, SygniRectButton, SygniTextArea, SygniCheckbox, SygniSelect, SygniInput, SygniContainerTitle, SygniLoader, GenproxButton, ChevronLeftIcon },
  computed: {
    ...mapState('campaigns', {
      leadFormFields: (state: any) => state.leadForm.fields,
    })
  }
})
export default class LeadFormModule extends Vue {
  ChevronLeftIcon = ChevronLeftIcon;
  hasLeadForm: boolean = false;
  isLoading: boolean = false;
  requiredOptions: string[] = [
    'first_name',
    'surname',
    'email',
    'privacy_policy_and_terms_and_conditions'
  ]
  values: any = {};
  leadFormGroups: any = [
    {
      label: 'Required data',
      fields: ['first_name', 'surname', 'email', 'privacy_policy_and_terms_and_conditions'],
      items: [],
    },
    {
      label: 'Consent data',
      fields: ['consent_to_the_processing_of_data_for_marketing_purposes', 'consent_to_the_processing_of_data_using_telecommunications_terminal_equipment_and_automatic_calling_systems', 'consent_to_investment_risks'],
      items: [],
    },
    {
      label: 'General data',
      fields: ['notes', 'investment_potential', 'investment_knowledge', 'industry'],
      items: [],
    },
    {
      label: 'Detailed data',
      fields: ['title', 'middle_name', 'birth_date', 'birth_place', 'fathers_name', 'mothers_name', 'phone_number', 'identification_number', 'document_type', 'document_number', 'document_expiry_date', 'document_country', 'country', 'nationality', 'bank_account', 'tax_office'],
      items: [],
    },
    {
      label: 'Address data',
      fields: ['residential_address_street', 'residential_address_house_number', 'residential_address_flat_number', 'residential_address_city', 'residential_address_zip_code', 'residential_address_voivodeship', 'residential_address_county', 'residential_address_commune', 'residential_address_country', 'mailing_address_street', 'mailing_address_house_number', 'mailing_address_flat_number', 'mailing_address_city', 'mailing_address_zip_code', 'mailing_address_voivodeship', 'mailing_address_county', 'mailing_address_commune', 'mailing_address_country'],
      items: [],
    }
  ]
  
  get options() {
    return this.$store.getters['campaigns/leadFormFields'];
  }

  get groups() {
    const groups = this.leadFormGroups;

    this.options.forEach((option: any) => {
      const group = this.leadFormGroups.find((el: any) => el.fields.includes(option));

      if(group) {
        group.items.push(option);
      } else {
        this.leadFormGroups[0].items.push(option);
      }
    })

    return groups;
  }

  getOption(name: string) {
    return this.values?.find((el: any) => el.name === name);
  }

  get leadFormItems() {
    return this.$store.getters['campaigns/leadFormData']?.parameters?.items ? this.$store.getters['campaigns/leadFormData'].parameters.items : [];
  }

  async saveChanges() {
    this.isLoading = true;
    try {
      const payload = this.values.filter((el: any) => el.visible).map((el: any) => {
        return { name: el.name, required: el.required };
      });

      await this.$store.dispatch('campaigns/postLeadForm', payload);
      this.$notify({
        duration: 2500,
        type: 'success',
        title: 'Success',
        text: 'Lead form saved.'
      });

      if (!this.hasLeadForm) {
        this.$router.go(-1)
      }

    } catch(e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
    }
    this.isLoading = false;
  }

  loadOptions() {
    this.values = this.options.map((el: string) => {
      const option = this.leadFormItems?.find((item: any) => item.name === el);
      return {
        name: el,
        visible: this.requiredOptions.includes(el) ? true : option ? true : false,
        required: option ? option.required : true,
      }
    })
  }

  @Watch('options') onOptionsChange() {
    this.loadOptions();
  }

  async mounted() {
    try {
      await this.$store.dispatch('campaigns/getLeadForm');
      this.hasLeadForm = true;
    } catch(e) {
      this.hasLeadForm = false;
      this.$notify({
        type: 'error',
        title: 'Action required',
        text: 'Configure your lead form'
      })
      e;
    }
    this.loadOptions();
  }
  
  validations() {
    return {
      investmentData: {
        firstName: { required },
        surname: { required },
        email: { required, email },
      }
    }
  }
}

